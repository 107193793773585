import dashboard from './dashboard'
import account from './account'
import payment from './payment'
import billing from './billing'
import productLinks from './productLinks'
// import referralOld from './referraOld'
import referralProgram from './referralProgram'
import partner from './partner'
// import platform from './platform'
import faq from './faq'

export default [
  ...dashboard,
  ...billing,
  ...productLinks,
  ...payment,
  ...account,
  // ...referralOld,
  ...referralProgram,
  ...partner,
  // ...platform,
  ...faq,
]
