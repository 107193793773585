/* eslint-disable camelcase */
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { SUBSCRIPTION_TYPE } from '@/constants'

const { mapGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersBilling } = createNamespacedHelpers('billing')

export default {
  computed: {
    ...mapGetters(['user', 'plansStatus']),
    ...mapGettersBilling(['subscriptionStatus']),

    isTrialing() {
      const { isTrialing } = this.plansStatus
      return isTrialing
    },

    needToBeReviewed() {
      const { needToBeReviewed } = this.plansStatus
      return needToBeReviewed
    },

    isBlackAgency() {
      const { activePlan } = this.plansStatus
      return activePlan === SUBSCRIPTION_TYPE.black_agency
    },

    isWhiteAgency() {
      const { activePlan } = this.plansStatus
      return activePlan === SUBSCRIPTION_TYPE.white_agency
    },

    checkHasPlan() {
      const { activePlan } = this.plansStatus
      return activePlan
    },

    getPlanName() {
      return this.user?.data?.plans?.type
    },

    noPlan() {
      const { outOfTrialAndNeedPlan } = this.plansStatus

      return !!((!this.isTrialing && !this.checkHasPlan) || outOfTrialAndNeedPlan || this.needToBeReviewed)
    },

    timePlanExpired() {
      let time = null
      const trialEndDate = _get(this.user, ['data', 'plans', 'subscription', 'trial_end'], null)
      const endDate = _get(this.user, ['data', 'plans', 'subscription', 'current_period_end'], null)

      if (endDate) {
        time = this.timeStampToDate(endDate)
      } else if (this.isTrialing && trialEndDate) {
        time = this.timeStampToDate(trialEndDate)
      }
      return time
    },

    paymentIntentStatus() {
      return _get(this.subscriptionStatus, ['latestInvoice', 'paymentIntent', 'status'], '')
    },

    latestInvoice() {
      return _get(this.subscriptionStatus, ['latestInvoice', 'paymentIntent'], {})
    },

    reconfirmPlanStatus() {
      const type = _get(this.subscriptionStatus, ['status'], '')
      return !!(type === 'incomplete' || type === 'incomplete_expired')
        && (this.paymentIntentStatus === 'requires_confirmation'
          || this.paymentIntentStatus === 'requires_action'
          || this.paymentIntentStatus === 'requires_payment_method')
    },

    hasPreviousPlan() {
      if (this.reconfirmPlanStatus) { // reconfirm incomplete previous subscription
        if (this.latestInvoice?.paymentMethod) {
          return true
        }
      }

      return false
    },

    previousPlanName() {
      return _get(this.subscriptionStatus, ['plans'])
    },
  },
}
