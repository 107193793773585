<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      'menu-dark',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2
                v-if="
                  !isVerticalMenuCollapsed ||
                    (isVerticalMenuCollapsed && isMouseHovered)
                "
                class="name-logo text-white"
              >
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <b-img
                alt="menu"
                :src="collapseTogglerIconFeather"
                class="d-none d-xl-block btn-toggle"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <hr
      class="divider"
      :class="{'mb-2': isPartnerViewMode === 'true'}"
    >
    <!-- / main menu header-->

    <!-- main menu balance-->
    <vertical-nav-menu-balance
      v-if="(!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)) && isPartnerViewMode !== 'true'"
    />

    <switch-partner-view
      v-if="isPartnerViewMode !== null"
      class="d-md-none d-block"
      style="padding: 10px 22px"
    />

    <!-- / main menu balance-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content -->
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions,global-require */

import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex'
import partnerMixin from '@/mixins/partnerMixin'
import SwitchPartnerView from '@/layouts/components/SwitchPartnerView.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import VerticalNavMenuBalance from './components/vertical-nav-menu-balance/verticalNavMenuBalance.vue'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  components: {
    SwitchPartnerView,
    VerticalNavMenuBalance,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },

  mixins: [partnerMixin],
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      hasReadPolicy: false,
    }
  },

  computed: {
    ...mapGetters(['user']),

    // balance() {
    //   return this.user.data?.balance?.toLocaleString('en-US') || 0
    // },
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned'
      ? require('@/assets/images/common/ic-menu-close-white.svg')
      : require('@/assets/images/common/ic-menu-open-white.svg')))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },

  methods: {
    gotoPolicy() {
      this.$router.push('/introduce?tab=introduction')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>

<style lang="scss" scoped>
.btn-toggle {
  width: 23px;
  margin-top: 32px;
}

.add-fund {
  padding: 1rem;
  margin: 1rem;
  background: #f8f8f8;
  border-radius: 5px;
}

.title-nav-mf {
  font-weight: bold;
  text-transform: uppercase;
  color: #b9b9c3;
  font-size: 12px;
  padding: 1rem;
}

.text-balance {
  color: #b9b9c3;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.fund {
  color: #5e5873;
  font-weight: bold;

  .currency {
    font-size: 15px;
  }

  .amount {
    font-size: 40px;
  }
}

.main-menu .main-menu-content {
  height: calc(100% - 17.45rem) !important;
}

.name-logo {
  padding: 0;
  margin-left: 0.5rem;
  margin-bottom: 0;
  width: 60px;
  line-height: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.01rem;
  font-size: 0.95rem;
  white-space: normal;
}

.divider {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 20px;
}

.terms-of-policy {
  position: absolute;
  bottom: 1.8rem;
  left: 2rem;
  cursor: pointer;
  color: #ffffff;
}
</style>
