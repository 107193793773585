<template>
  <div
    class="cursor-pointer position-relative ak-trigger"
    style="margin-right: 4px"
  >
    <feather-icon
      icon="BellIcon"
      size="21"
    />
    <announce-kit
      catch-click=".ak-trigger"
      widget="https://announcekit.app/widgets/v2/4k3nRC"
      :widget-style="{top : '-6px', left: '-11px', position: 'absolute'}"
    />
  </div>
</template>
<script>
// import {} from 'bootstrap-vue'

import AnnounceKit from 'announcekit-vue'

export default {
  components: {
    AnnounceKit,
  },
}
</script>
