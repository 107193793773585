<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemsCustom"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import partnerMixin from '@/mixins/partnerMixin'
import envMixin from '@/mixins/envMixin'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },

  mixins: [stepGuideMixin, partnerMixin, envMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },

  computed: {
    itemsCustom() {
      const itemConverted = this.handleShowNav(this.items)
      if (this.hasStepGuide && this.isPartnerViewMode !== 'true') {
        if (this.isDoneChoosePlan) {
          return itemConverted.map(item => ({
            ...item,
            ...(!this.isDoneUpdateProductLink && !this.isDoneAddFund && item.route === 'payment' ? { disabled: true } : {}),
            ...(!this.isDoneAddFund && item.route === 'my-ads-account' ? { disabled: true } : {}),
          }))
        }
        return itemConverted.map(item => ({
          ...item,
          ...(!item.route.includes('referral') && item.route !== 'dashboard' && item.route !== 'billing' ? { disabled: true } : {}),
        }))
      }

      return itemConverted
    },
  },

  methods: {
    handleShowNav(router) {
      if (this.isPartnerViewMode === 'true') { // filter nav just for partner.
        return router.filter(item => {
          if (this.hasAcl) {
            return item.route.startsWith('partner')
          }
          return item.route.startsWith('partner') && !item.route.includes('partner-management-product-link') // hide member product link if partner not have acl
        })
      }
      let routerSeller = router.filter(item => {
        if (this.isPartner) {
          return !item.route.startsWith('partner') && !item.route.startsWith('referral-introduce') // Not show referral link if partner is seller
        }
        return !item.route.startsWith('partner')
      })

      if (!this.isEcomdyPlatform) {
        routerSeller = routerSeller.filter(item => item.route !== 'faq') // Do not show faq page if platform is not Ecomdy
      }

      if (this.isMember) {
        return routerSeller.filter(item => item.route !== 'referral-introduce') // filter nav just for member.
      }
      return routerSeller.filter(item => item.route !== 'referral-partner-info') // filter nav just for normal user.
    },
  },
}
</script>
