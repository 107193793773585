export default [
  {
    title: 'Referral Program',
    route: 'referral-introduce',
    icon: 'AwardIcon',
  },
  {
    title: 'Partner Contact',
    route: 'referral-partner-info',
    icon: 'AwardIcon',
  },
]
