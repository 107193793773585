<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <app-breadcrumb />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <switch-partner-view
        v-if="isPartnerViewMode !== null"
        class="d-none d-md-block mr-1"
      />
      <current-plan v-if="isPartnerViewMode !== 'true'" />

      <app-notification v-if="isEcomdyPlatform" />
      <app-help-center v-if="isEcomdyPlatform" />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        style="padding-left: 5px"
      >
        <template #button-content>
          <b-avatar
            size="26"
            variant="light-secondary"
          >
            <feather-icon icon="UserIcon" />
          </b-avatar>
          <div class="d-sm-flex d-none user-nav">
            <p
              class="user-name font-weight-600 mb-0 font-14"
              style="margin-left: 0"
            >
              {{ fullName }}
            </p>
          </div>
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{name: 'profile'}"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textProfile') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textLogOut') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle,camelcase */

import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem,
  BAvatar,
} from 'bootstrap-vue'
import Moralis from 'moralis'
import { createNamespacedHelpers } from 'vuex'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNotification from '@core/components/app-notification/AppNotification.vue'
import AppHelpCenter from '@core/components/app-help/AppHelp.vue'
import get from 'lodash/get'
import { TRANSACTION_TYPE } from '@/constants'
import partnerMixin from '@/mixins/partnerMixin'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import CurrentPlan from './CurrentPlan.vue'
import SwitchPartnerView from './SwitchPartnerView.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
const { mapActions: mapActionsPayment } = createNamespacedHelpers('payment')

export default {
  components: {
    SwitchPartnerView,
    CurrentPlan,
    AppBreadcrumb,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    AppNotification,
    AppHelpCenter,
  },
  mixins: [partnerMixin, billingMixin, envMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['user', 'plansStatus']),
    fullName() {
      const fullName = get(this.user, ['data', 'fullName'], '')
      const email = get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    // avatarURL() {
    //   return get(this.user, ['data', 'imageUrl'], defaultAvavar)
    // },
  },

  watch: {
    user: {
      handler(val) {
        // const userCountryCode = get(this.user, ['data', 'signupTracking', 'country'])
        // const totalSpent = get(this.user, ['data', 'totalSpent'])
        // const totalSpentRequired = 1000
        // if (userCountryCode) {
        //   const hasBlock = BLOCKED_COUNTRIES.includes(userCountryCode)
        //   if (!hasBlock || (hasBlock && totalSpent >= totalSpentRequired)) {
        //     this.initFreshChat(val)
        //   } else {
        //     window.FreshworksWidget('show')
        //     this.initTicket(val)
        //   }
        // }
        if (this.isEcomdyPlatform) {
          this.initFreshChat(val)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (!this.user.status) {
      this.getAccountInfo() // fetch user info if before router don't fetch.
    }
    this.getAccountReport()
    this.getGeneralConfig()
    this.fetchHistory() // get history add fund user for step guide, side bar.
  },

  methods: {
    ...mapActions(['logout', 'getAccountInfo', 'getGeneralConfig', 'getAccountReport', 'updateAccount']),
    ...mapActionsPayment(['getHistoryLimit']),
    async onLogout() {
      // start logout moralis
      await Moralis.initialize(process.env.VUE_APP_MORALIS_ID)
      if (Moralis.User.current()) {
        await Moralis.User.logOut()
        localStorage.setItem('ethAddress', '')
      }
      // end logout moralis
      this.logout()
      this.$router.push({ name: 'login' })
    },

    initFreshChat(val) {
      const plugin = document.createElement('script')
      plugin.setAttribute(
        'src',
        'https://wchat.freshchat.com/js/widget.js',
      )
      plugin.onload = () => {
        const email = get(val, ['data', 'email'], '')
        const localUserId = get(val, ['data', 'localUserId'], null)
        const restoreId = get(val, ['data', 'freshChatRestoreId'], null)
        const fullName = get(val, ['data', 'fullName'], '')
        const adsAccount = get(val, ['data', 'adsAccounts'], [])
        const phoneData = get(val, ['data', 'contactInfo', 'phone'], 'N/A')
        const totalSpent = get(val, ['data', 'totalSpending'], 'N/A')
        const userCountryCode = get(val, ['data', 'signupTracking', 'countryName'])
        const userIp = get(val, ['data', 'signupTracking', 'ip'])
        const userAgent = get(val, ['data', 'signupTracking', 'userAgent'])
        const profileBaseUrl = 'https://tiktok-admin.ecomdymedia.com'
        if (email && window.fcWidget) {
          window.fcWidget.init({
            token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
            host: 'https://wchat.freshchat.com',
            locale: 'en',
            externalId: localUserId,
            restoreId,
            email,
            firstName: `${localUserId}_${userCountryCode}_`,
            lastName: fullName,
            phone: this.getPhoneData('phone'),
          })

          window.fcWidget.user.setProperties({
            phone: this.getPhoneData('phone'),
            plan: this.getPlanName,
            totalSpent: `${totalSpent}$`,
            totalAdsAccount: Array.isArray(adsAccount) ? adsAccount.length : 0,
            phoneData,
            profileUrl: `${profileBaseUrl}/user/${localUserId}`,
            ip: userIp,
            userAgent,
          })
          this.saveUserInfoToFreshChat()
        }
      }
      plugin.async = true
      return document.head.appendChild(plugin)
    },

    initTicket(val) {
      if (window.FreshworksWidget) {
        const email = get(val, ['data', 'email'], '')
        const localUserId = get(val, ['data', 'localUserId'], null)
        const fullName = get(val, ['data', 'fullName'], '')
        const userCountryCode = get(val, ['data', 'signupTracking', 'country'])
        window.FreshworksWidget('identify', 'ticketForm', {
          name: `${localUserId}_${userCountryCode}_${fullName}`,
          email,
        })
        window.FreshworksWidget('disable', 'ticketForm', ['email'])
        window.FreshworksWidget('hide', 'ticketForm', ['name'])
      }
    },

    saveUserInfoToFreshChat() {
      window.fcWidget.user.get(resp => {
        const status = resp && resp.status
        if (status !== 200) {
          window.fcWidget.on('user:created', createdResp => {
            const createdStatus = createdResp && createdResp.status
            const createdData = createdResp && createdResp.data
            if (createdStatus === 200) {
              if (createdData.restoreId) {
                // Update restoreId into database
                const params = {
                  ...this.user.data,
                  freshChatRestoreId: createdData.restoreId,
                }
                this.updateAccount(params)
              }
            }
          })
        }
      })
    },

    getPhoneData(type) {
      const contactInfo = get(this.user, ['data', 'contactInfo'], {})
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = phoneElm
          switch (type) {
            case 'phone': return phone
            case 'countryCode': return countryCode
            case 'countryAbbr': return countryAbbr
            default: return ''
          }
        }
      }
      return ''
    },

    async fetchHistory() {
      const params = {
        page: 1,
        size: 5,
        types: [TRANSACTION_TYPE.RECHARGE_USER_BALANCE, TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY],
      }
      await this.getHistoryLimit(params)
    },
  },
}
</script>
<style lang="scss">
</style>
