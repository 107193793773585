import { createNamespacedHelpers } from 'vuex'
import { PROFILE_STATUS, PAYMENT_METHOD } from '@/constants'
import adsAccount from '@/mixins/adsAccount'
import billingMixin from '@/mixins/billingMixin'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersPayment } = createNamespacedHelpers('payment')
export default {
  data() {
    return {}
  },

  mixins: [adsAccount, billingMixin],

  computed: {
    ...mapGettersAuth(['user']),
    ...mapGettersPayment(['listBusinessAccountHistoryLimit', 'loading']),

    profileStatus() {
      return this.user?.data?.products?.status
    },

    isNewUser() {
      return !this.user?.data?.shouldSkipProductVerify
    },

    isWhiteListUser() {
      return this.user?.data?.whitelistProductVerify
    },

    hasStepGuide() {
      if (this.isWhiteListUser) { // don't show step guide for whitelist user
        return false
      } if (this.isNewUser) {
        return !this.isDoneAddFund || !this.isDoneConnectTikTok // new user who doesn't add fund or doesn't connect tiktok will appear step guide
      }
      return false
    },

    isDoneUpdateProductLink() {
      return this.listApprovedProductLink && Array.isArray(this.listApprovedProductLink) && this.listApprovedProductLink.length > 0
    },

    isDoneChoosePlan() {
      return (this.checkHasPlan || this.isTrialing) && !this.needToBeReviewed
    },

    isDoneConnectTikTok() {
      return this.hasConnectTiktok
    },

    isDoneAddFund() {
      const listTransactionExcludeUSDT = this.listBusinessAccountHistoryLimit?.content?.filter(item => item.paymentMethod !== PAYMENT_METHOD.USDT)
      const listUSDTransaction = this.listBusinessAccountHistoryLimit?.content?.filter(item => item.paymentMethod === PAYMENT_METHOD.USDT)

      if (listTransactionExcludeUSDT.length > 0) {
        return true
      } if (listUSDTransaction.length > 0) {
        const hasApproveTransaction = _find(listUSDTransaction, { status: 'done' })
        if (hasApproveTransaction) {
          return true
        }
      }

      return false
    },

    listProductLink() {
      const listProductUrl = this.user?.data?.products?.productURLs || {}

      const arrUrlConverted = Object.keys(listProductUrl).map(value => ({
        productUrl: value,
        ...listProductUrl[value],
      }))

      return arrUrlConverted || []
    },

    hasProductLink() {
      return !_isEmpty(this.user?.data?.products?.productURLs)
    },

    listApprovedProductLink() {
      return this.listProductLink.filter(productUrl => productUrl.status === PROFILE_STATUS.APPROVED) || []
    },
  },
}
