<template>
  <div
    class="mx-1 d-flex justify-content-between align-items-baseline flex-wrap"
    style="padding: 16px 6px"
  >
    <span class="mr-1 font-14">
      Balance
    </span>
    <div class="d-flex align-items-center">
      <b-spinner
        v-if="loading"
        class="d-block"
        small
      />
      <span
        v-else
        class="font-20 font-weight-bold text-white"
      >
        ${{ userBalance }}
      </span>
      <img
        v-if="showIcon"
        :src="require('@/assets/images/common/ic-add-fund-yellow.svg')"
        alt="image"
        class="cursor-pointer ml-1"
        @click="goToAddFund"
      >
    </div>
  </div>
</template>
<script>
import {
  BSpinner,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

import _get from 'lodash/get'

const { mapGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersBusinessAccountHistoryLimit } = createNamespacedHelpers('payment')

export default {
  components: {
    BSpinner,
    // components
  },
  mixins: [numberFormatMixin, stepGuideMixin],
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['user']),
    ...mapGettersBusinessAccountHistoryLimit(['listBusinessAccountHistoryLimit']),

    userBalance() {
      const amount = _get(this.user, ['data', 'balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },

    loading() {
      return _get(this.user, ['loading'], false)
    },

    hasShowIntro() {
      return this.listBusinessAccountHistoryLimit?.paging?.total === 0
    },

    showIcon() {
      if (this.hasStepGuide && this.isDoneChoosePlan) {
        return this.isDoneUpdateProductLink || this.isDoneAddFund
      }
      return true
    },
  },

  methods: {
    goToAddFund() {
      if (this.noPlan) {
        this.$router.push('/pricing')
      } else if (this.hasShowIntro) {
        this.$router.push('/payment')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },
  },
}
</script>
