<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-help"
  >
    <template #button-content>
      <feather-icon
        v-b-tooltip.hover.top="'Help'"
        icon="HelpCircleIcon"
        size="21"
      />
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://ecomdymedia.freshdesk.com/support/home"
      target="_blank"
    >
      <span>Help Center</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="openContact"
    >
      <span>Contact Support</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      to="/pricing"
    >
      <span>Plans and Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://blog.ecomdymedia.com"
      target="_blank"
    >
      <span>Blog</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      href="https://ecomdymedia.com/terms-policy"
      target="_blank"
    >
      <span>Term and policy</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
// import {} from 'bootstrap-vue'
import {
  VBTooltip, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    openContact() {
      if (window?.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },
  },
}
</script>
<style lang="scss">
.dropdown-help .dropdown-menu {
  width: 12rem;
}
</style>
