<template>
  <b-button
    variant="outline-warning"
    pill
    size="sm"
    class="font-medium d-flex align-items-center justify-content-center font-small-2"
    style="margin-right: 13px"
    to="/billing"
  >
    <img
      v-if="noPlan && !needToBeReviewed"
      alt="tick"
      :src="require('@/assets/images/pages/billing/crown-active.svg')"
      class="img-crown"
    >
    {{ noPlan && !needToBeReviewed ? 'Upgrade' : getPlanName }}
  </b-button>
</template>

<script>
/* eslint-disable camelcase */

import {
  BButton,
} from 'bootstrap-vue'
import billingMixin from '@/mixins/billingMixin'

export default {
  components: {
    BButton,
  },
  mixins: [billingMixin],
}
</script>
<style lang="scss" scoped>
  .img-crown{
    width: 17px;
    margin-right: 6px;
  }
</style>
